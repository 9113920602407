<template>
  <v-row
    no-gutters
    class="box pb-0"
  >
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="titulo">Remessas Internacionais</h1>
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        justify="end"
      >
        <v-col cols="auto">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
        </v-col>

        <v-col class="shrink d-flex align-end pl-2">
          <operation-filter-drawer
            v-model="filters"
            :bank-list="bankList"
            :currency-list="currencyList"
            :exchange-type-id="exchangeTypeId"
            @filter="getOperations"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="pt-2"
      >
        <v-col
          v-if="!operations.length"
          cols="12"
        >
          <EmptyTable
            title="Nenhum registro encontrado"
            icon="far fa-hand-holding-usd"
            subtitle="Não há operações de Câmbio Remessa para o período selecionado."
          />
        </v-col>

        <v-col
          v-else
          cols="12"
          :key="key"
        >
          <v-card class="card-cambio">
            <v-data-table
              :expanded.sync="expanded"
              show-expand
              :headers="columns"
              :items="operations"
              :search="search"
              sort-by="effectiveOperationDate"
              sort-desc
              :items-per-page="10"
              :custom-filter="caseInsensitiveAccentsInsensitive"
              @click:row="clickRow"
              height="calc(100dvh - 300px)"
              :custom-sort="customSort"
              fixed-header
            >
              <template #[`item.effectiveOperationDate`]="{ item }">
                <span>{{ item.effectiveOperationDate | dateTime }}</span>
              </template>

              <template #[`item.customer.customerNameOrCompanyName`]="{ item }">
                <span
                  class="customer-name"
                  v-if="item.customer.isPJ()"
                >{{ item.customer.companyName }}</span>
                <span
                  class="customer-name"
                  v-else
                >{{ item.customer.name }}
                </span>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      @click="goToCustomer(item.customer.id)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="far fa-eye" />
                    </v-btn>
                  </template>
                  <span>Visualizar cliente</span>
                </v-tooltip>
              </template>

              <template #[`item.status.description`]="{ item }">
                <v-chip
                  small
                  label
                  :color="item.status.id | statusColor"
                >
                  {{ item.status.description }}
                </v-chip>
              </template>

              <template #[`item.exchangeBuy`]="{ item }">
                <v-chip
                  v-if="item.exchangeBuy"
                  small
                  label
                  color="teal lighten-4"
                >
                  Compra
                </v-chip>
                <v-chip
                  v-else
                  small
                  label
                  color="red lighten-4"
                >
                  Venda
                </v-chip>
              </template>

              <template #[`item.amount`]="{ item }">
                <span>{{ item.currency.code }} - {{ item.amount | formatCurrency(item.currency.code) }}</span>
              </template>

              <template #[`item.customerRate`]="{ item }">
                <span>{{ item.customerRate | formatCurrency('BRL', 5) }}</span>
              </template>

              <template #[`item.totalValue`]="{ item }">
                <span>{{ item.totalValue | formatCurrency('BRL') }}</span>
              </template>

              <template #[`item.cc`]="{ item }">
                <DataTableDocumentInput
                  download-file-name="CC"
                  :callback-add-document="addDocument"
                  :callback-download-document="downloadDocument"
                  v-model="item.cc"
                />
              </template>

              <template #[`item.swift`]="{ item }">
                <DataTableDocumentInput
                  download-file-name="SWIFT"
                  :callback-add-document="addDocument"
                  :callback-download-document="downloadDocument"
                  v-model="item.swift"
                />
              </template>

              <template #[`item.didue`]="{ item }">
                <div
                  v-if="item.nature.useDiDue"
                  class="d-inline-flex"
                >
                  <DataTableDocumentInput
                    download-file-name="DI/DUE"
                    :callback-add-document="addDocument"
                    :callback-download-document="downloadDocument"
                    v-model="item.didue"
                  />
                </div>
                <span v-else>-</span>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu
                  offset-y
                  offset-overflow
                  left
                  slide-y
                  @click.stop
                >
                  <template #activator="{ on: menu }">
                    <v-tooltip
                      top
                    >
                      <template #activator="{ on: tooltip, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                          @click.stop
                        >
                          <i class="far fa-ellipsis-v" />
                        </v-btn>
                      </template>
                      <span>Opções</span>
                    </v-tooltip>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="item.nature.useDiDue && !item.didue.path"
                      @click="requestDiDue(item)"
                    >
                      <v-list-item-title>Solicitar D.I./D.U.E.</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="item.status.id == OPERATION_WORKFLOW.EM_ANALISE.id || item.status.id == OPERATION_WORKFLOW.ANALISE_PENDENCIAS.id"
                      @click="openOperationPendencyModal(item)"
                    >
                      <v-list-item-title>Registrar pendências</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="item.status.id == OPERATION_WORKFLOW.COM_PENDENCIAS.id"
                      @click="analysisOperationPendency(item)"
                    >
                      <v-list-item-title>Análise de pendências</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="item.status.id != OPERATION_WORKFLOW.CANCELADA.id"
                      @click="cancelOperation(item)"
                    >
                      <v-list-item-title>Cancelar Operação</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="openDocumentControlModal(item)"
                    >
                      <v-list-item-title>Documentos</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <!-- Expanded Content -->
              <template #expanded-item="{ item }">
                <td :colspan="columns.length + 1">
                  <v-card class="box card-cambio my-5">
                    <v-row>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Nº da OP</span>
                        <p class="expand-value">{{ item.id }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Taxa do banco</span>
                        <p class="expand-value">{{ item.bankRate || 0 | formatCurrency('BRL', 5) }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Taxa do Cliente</span>
                        <p class="expand-value">{{ item.customerRate || 0 | formatCurrency('BRL', 5) }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">IOF</span>
                        <p class="expand-value">{{ item.iofValue || 0 | formatCurrency('BRL') }} ({{ item.iof || 0 | formatNumber }} %)</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">IR</span>
                        <p class="expand-value">{{ item.irValue || 0 | formatCurrency('BRL') }} ({{ item.ir || 0 | formatNumber }} %)</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Tarifa</span>
                        <p class="expand-value">{{ item.exchangeContractCostBrl || 0 | formatCurrency('BRL') }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span class="expand-title">Agente</span>
                        <p class="expand-value">{{ item.administrative.agentData.name }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span class="expand-title">Corban</span>
                        <p class="expand-value">{{ item.administrative.indicatorData.name }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span class="expand-title">Banco</span>
                        <p class="expand-value">{{ item.bank.fantasyName }}</p>
                      </v-col>

                      <v-col
                        cols="12"
                        col="12"
                        v-if="!item.exchangeBuy"
                      >
                        <span class="expand-title">Conta Bancária Internacional</span>
                        <v-card class="box card-cambio my-5">
                          <v-row>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Banco</span>
                              <p class="expand-value">{{ item.account.bank }}</p>
                            </v-col>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">IBAN</span>
                              <p class="expand-value">{{ item.account.numberIBAN }}</p>
                            </v-col>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Código Swift/BIC</span>
                              <p class="expand-value">{{ item.account.swiftCodeBIC }}</p>
                            </v-col>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Número ABA/Routing</span>
                              <p class="expand-value">{{ item.account.tabNumberRouting }}</p>
                            </v-col>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Nome do titular</span>
                              <p class="expand-value">{{ item.account.ownerName }}</p>
                            </v-col>

                            <v-col
                              cols="12"
                              md="2"
                              v-if="item.account.forFurtherCredit"
                            >
                              <span class="expand-title">FFC</span>
                              <p class="expand-value">{{ item.account.forFurtherCredit }}</p>
                            </v-col>

                            <template
                              v-if="item.account.intermediary"
                            >
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <span class="expand-title">País Intermediário</span>
                                <p class="expand-value">{{ item.account.intermediaryCountry }}</p>
                              </v-col>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <span class="expand-title">Banco Intermediário</span>
                                <p class="expand-value">{{ item.account.intermediaryBankName }}</p>
                              </v-col>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <span class="expand-title">ABA/Routing Intermediário</span>
                                <p class="expand-value">{{ item.account.intermediaryAbaSwiftCode }}</p>
                              </v-col>
                            </template>
                          </v-row>
                        </v-card>
                      </v-col>

                      <v-col
                        cols="12"
                        col="12"
                        v-else
                      >
                        <span class="expand-title">Conta Bancária do Brasil</span>
                        <v-card class="box card-cambio my-5">
                          <v-row>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Banco</span>
                              <p class="expand-value">{{ item.account.name }}</p>
                            </v-col>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Agência</span>
                              <p class="expand-value">{{ item.account.agency }}</p>
                            </v-col>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Conta</span>
                              <p class="expand-value">{{ item.account.account }}</p>
                            </v-col>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <span class="expand-title">Digito</span>
                              <p class="expand-value">{{ item.account.digit }}</p>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <!-- Documents Control Modal -->
          <v-dialog
            persistent
            v-model="documentControlModal"
            max-width="40vw"
            :key="modalKey"
          >
            <v-card>
              <v-card-title>
                <h1 class="titulo-modal">Controle de Documentos</h1>
              </v-card-title>
              <v-divider />
              <v-card-text class="py-5">
                <v-row class="py-5">
                  <v-col
                    class="py-0"
                    cols="12"
                  >
                    <DocumentInput
                      description="Contrato de Câmbio"
                      v-model="operation.cc"
                      :api="apiDocument"
                      :operation-id="operation.id"
                      :disabled="!operation.cc?.operationDocumentType?.id"
                      :file-type="fileType.OPERATION_DOCUMENT"
                      :document-post-save-callback="documentPostSaveCallback"
                    />
                  </v-col>
                  <v-divider class="pa-2" />
                  <v-col
                    class="py-0"
                    cols="12"
                  >
                    <DocumentInput
                      description="SWIFT"
                      v-model="operation.swift"
                      :api="apiDocument"
                      :operation-id="operation.id"
                      :disabled="!operation.swift?.operationDocumentType?.id"
                      :file-type="fileType.OPERATION_DOCUMENT"
                      :document-post-save-callback="documentPostSaveCallback"
                    />
                  </v-col>
                  <template v-if="operation.nature.useDiDue">
                    <v-divider class="pa-2" />
                    <v-col
                      class="py-0"
                      cols="12"
                    >
                      <DocumentInput
                        description="D.I./D.U.E."
                        v-model="operation.didue"
                        :api="apiDocument"
                        :operation-id="operation.id"
                        :disabled="!operation.didue?.operationDocumentType?.id"
                        :file-type="fileType.OPERATION_DOCUMENT"
                        :document-post-save-callback="documentPostSaveCallback"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-col class="text-right">
                  <v-btn
                    text
                    class="btn-tertiary"
                    @click="documentControlModal = false"
                  >
                    Fechar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <!-- Modal de Informações Adicionais da Pendência -->
          <v-dialog
            persistent
            scrollable
            eager
            min-width="20vw"
            max-width="80vh"
            v-model="pendencyComplentaryInfoModal"
          >
            <v-card>
              <v-card-title>
                <h1 class="titulo-modal">Informação complementar</h1>
              </v-card-title>
              <v-divider class="" />
              <v-card-text class="pt-8">
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                  >
                    <v-textarea
                      auto-grow
                      outlined
                      rows="1"
                      v-model="pendencyComplentaryInfo"
                      label="Informações complementares"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-col class="text-right">
                  <v-btn
                    text
                    class="btn-primary mr-2"
                    @click="registerOperationPendency()"
                  >
                    Salvar
                  </v-btn>
                  <v-btn
                    text
                    class="btn-tertiary"
                    @click="pendencyComplentaryInfoModal = false"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <ConfirmationWindow
            v-model="openConfirmationWindow"
            :callback-primary="confirmationWindowCallback"
            :message="confirmationWindowMessage"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

// Api
import apiOperation from "@/api/operation/operation-api";
import apiCurrency from "@/api/spread/currency-api";
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiOperationDocuments from "@/api/operation/operation-documents-api";
import apiTrigger from "@/api/generic/trigger-api";

// Models
import OperationModel from "@/model/operation-model";
import OperationFiltersModel from "@/model/operation-filters-model";
import CurrencyModel from "@/model/currency-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import OperationDocumentModel from "@/model/operation-document-model";
import TriggerModel from "@/model/generic/trigger-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";
import mixinExpandedDataTable from "@/mixin/mixin-data-table-expanded";

// Utils
import dateUtils from "../../../../common/utils/date";
import numberUtils from "../../../../common/utils/number";
import { customSortDataTable } from "@/utils/dataTable";

// Components
import DataTableDocumentInput from "@/components/comum/DataTableDocumentInput.vue";
import EmptyTable from "@/components/comum/EmptyTable.vue";
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";
import DocumentInput from "@/components/comum/DocumentInput";
import OperationFilterDrawer from '@/components/comum/OperationFilterDrawer.vue';

// Constants
import {
  OPERATION_DOCUMENT_TYPES,
  FILE_TYPE
} from "@/constants/general-constants";
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';
import { OPERATION_TYPE } from '../../../../common/constants/generic/types';

// Mixpanel
import mixpanel from "mixpanel-browser";

export default {
  name: "RemittanceOperationReport",
  mixins: [mixinMessage, mixinDataTableFilters, mixinExpandedDataTable],
  components: {
    DataTableDocumentInput,
    EmptyTable,
    ConfirmationWindow,
    DocumentInput,
    OperationFilterDrawer
  },
  data() {
    return {
      search: "",
      operations: [],
      columns: [
        { text: "Data", value: "effectiveOperationDate", align: "left", sortable: true, width: "180px" },
        { text: "Cpf/Cnpj", value: "customer.cpfCnpj", align: "left", sortable: true, width: "160px" },
        { text: "Cliente / Razão Social", value: "customer.customerNameOrCompanyName", align: "left", sortable: true, width: "500px" },
        { text: "Tipo", value: "exchangeBuy", align: "center", sortable: true, width: "10px" },
        { text: "Liquidação", value: "liquidation.description", align: "center", sortable: true, width: '120px' },
        { text: "Valor M.E.", value: "amount", align: "left", sortable: true, width: '200px' },
        { text: "Tx. Cliente.", value: "customerRate", align: "left", sortable: true, width: '120px' },
        { text: "Valor Total", value: "totalValue", align: "left", sortable: true },
        { text: "Natureza da Operação", value: "nature.exibitionName", align: "left", sortable: true, width: '200px' },
        { text: "C.C.", value: "cc", align: "center", sortable: true, width: '80px' },
        { text: "SWIFT", value: "swift", align: "center", sortable: true, width: '100px' },
        { text: "D.I./D.U.E.", value: "didue", align: "center", sortable: true, width: '110px' },
        { text: "Status", value: "status.description", align: "center", sortable: true, width: "10px" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      filters: new OperationFiltersModel(),
      currencyList: [],
      bankList: [],
      triggerReceived: new TriggerModel(),
      openConfirmationWindow: false,
      OPERATION_WORKFLOW,
      documentControlModal: false,
      operation: new OperationModel(),
      apiDocument: apiOperationDocuments,
      key: 0,
      modalKey: 0,
      fileType: FILE_TYPE,
      confirmationWindowMessage: '',
      confirmationWindowCallback: () => {},
      pendencyComplentaryInfoModal: false,
      pendencyComplentaryInfo: '',
      exchangeTypeId: OPERATION_TYPE.REMMITANCE.id
    };
  },
  watch: {
    "operation.cc"() {
      this.key++;
    },
    "operation.swift"() {
      this.key++;
    },
    "operation.didue"() {
      this.key++;
    },
  },
  mounted() {
    this.getCurrencies();
    this.getBankList();
    this.getOperations();
  },
  computed: {},
  filters: {
    dateTime(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
    formatCurrency(value, code, precison) {
      code = code || 'USD';

      return numberUtils.formatCurrency(code, value, precison);
    },
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR').format(value);
    },
    statusColor(statusId) {
      switch (statusId) {
        case OPERATION_WORKFLOW.EM_ANALISE.id:
          return 'operationWorkflowStatusEmAnalise';
        case OPERATION_WORKFLOW.COM_PENDENCIAS.id:
          return 'operationWorkflowStatusComPendencias';
        case OPERATION_WORKFLOW.ANALISE_PENDENCIAS.id:
          return 'operationWorkflowStatusAnalisePendencias';
        case OPERATION_WORKFLOW.EM_LIQUIDACAO.id:
          return 'operationWorkflowStatusEmLiquidacao';
        case OPERATION_WORKFLOW.LIQUIDADA.id:
          return 'operationWorkflowStatusLiquidada';
        case OPERATION_WORKFLOW.CANCELADA.id:
          return 'operationWorkflowStatusCancelada';
        default:
          break;
      }
    },
  },
  methods: {
    goToCustomer(id) {
      mixpanel.track("click", {
        button_name: "edit_customer",
      });
      this.$router
        .push({
          name: "FormEditCustomer",
          params: {
            id: id,
          },
        })
        .catch(() => { });
    },
    getOperations() {
      this.$eventBus.$emit("show-loading", true);

      apiOperation
        .find(this.filters)
        .then((response) => {
          this.operations = response.data.map((o) => {
            let operation = new OperationModel(o);

            operation.cc = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.CC.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.CC });
            operation.swift = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.SWIFT.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.SWIFT });
            operation.didue = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.DIDUE.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.DIDUE });

            return operation;
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage(
              (error.response && error.response.data.mensagem) || error,
              "error"
            );
          }

          this.operations = [];
        })
        .finally(() => {
          this.$eventBus.$emit("show-loading", false);
        });
    },
    getCurrencies() {
      if (this.currencyList[0]) {
        return;
      }

      apiCurrency.findAll()
        .then((response) => {
          this.currencyList = response.data.map(
            (c) => new CurrencyModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.bankList = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
 
    addDocument(document) {
      this.$eventBus.$emit("show-loading", true);
      let formData = new FormData();


      formData.append("operationId", document.operationId);
      formData.append("idOperationDocumentType", document.operationDocumentType?.id);
      formData.append("document", document.document);

      return apiOperationDocuments
        .add(formData)
        .then((response) => {
          this.receivedDocumentTrigger(response.data);

          let operation = this.operations.find(o => o.id == document.operationId);

          if (OPERATION_DOCUMENT_TYPES.CC.id == document.operationDocumentType?.id && !operation.exchangeBuy) {
            this.updateOperationStatus(document.operationId, OPERATION_WORKFLOW.EM_LIQUIDACAO);
          } else if (OPERATION_DOCUMENT_TYPES.SWIFT.id == document.operationDocumentType?.id || OPERATION_DOCUMENT_TYPES.CC.id == document.operationDocumentType?.id) {
            this.updateOperationStatus(document.operationId, OPERATION_WORKFLOW.LIQUIDADA);
          }

          return document = {
            ...response.data,
          };
        })
        .catch(() => {
          this.sendMessage("Erro ao adicionar o anexo!", "error");

          return document = {
            operationDocumentType: document.operationDocumentType,
          };
        })
        .finally(() => {
          this.$eventBus.$emit("show-loading", false);
        });
    },

    downloadDocument(document) {
      return apiOperationDocuments
        .download(document.id);
    },

    requestDiDue(operation) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_SOLICITA_DIDUE,
        idOperation: operation.id,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Solicitação de envio de D.I./D.U.E. enviada com sucesso!", "success");
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    receivedDocumentTrigger(document) {
      let triggerCode = '';

      switch (`${document.operationDocumentType?.id}`) {
        case `${OPERATION_DOCUMENT_TYPES.CC.id}`:
          triggerCode = SEND_EMAIL_TRIGGER_CODES.OPERACAO_ENVIO_CONTRATO_CAMBIO;
          break;
        case `${OPERATION_DOCUMENT_TYPES.SWIFT.id}`:
          triggerCode = SEND_EMAIL_TRIGGER_CODES.OPERACAO_ENVIO_SWIFT;
          break;
        case `${OPERATION_DOCUMENT_TYPES.DIDUE.id}`:
          triggerCode = SEND_EMAIL_TRIGGER_CODES.OPERACAO_ENTREGA_DIDUE;
          break;
        default:
          return;
      }

      this.triggerReceived.code = triggerCode,
      this.triggerReceived.idOperation = document.operationId;
      this.triggerReceived.idCustomer = this.operations.find(i => i.id == document.operationId).customer.id;
      this.triggerReceived.documentIds = [document.id];

      this.confirmationWindowMessage = 'Deseja enviar ao cliente?';
      this.confirmationWindowCallback = this.receivedDocumentTriggerCallback;
      this.openConfirmationWindow = true;
    },

    receivedDocumentTriggerCallback() {
      apiTrigger
        .trigger(this.triggerReceived)
        .then(() => {
          this.sendMessage("Documento enviado com sucesso!", "success");
          this.openConfirmationWindow = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateOperationStatus(operationId, workflow) {
      apiOperation
        .updateStatus(operationId, workflow)
        .then(() => {
          let operationFromList = this.operations.find(o => o.id == operationId);
          operationFromList.status = workflow;
        })
        .catch(() => {});
    },

    cancelOperation(operation) {
      this.operation = operation;
      this.confirmationWindowMessage = 'Deseja cancelar essa operação?';
      this.confirmationWindowCallback = this.cancelOperationCallback;
      this.openConfirmationWindow = true;
    },

    cancelOperationCallback() {
      apiOperation
        .cancelOperation(this.operation.id)
        .then(() => {
          let operationFromList = this.operations.find(o => o.id == this.operation.id);
          operationFromList.status = OPERATION_WORKFLOW.CANCELADA;
          this.sendMessage("A operação foi cancelada!", "success");
          this.openConfirmationWindow = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openOperationPendencyModal(operation) {
      this.operation = operation;
      this.pendencyComplentaryInfo = '';
      this.pendencyComplentaryInfoModal = true;
    },

    registerOperationPendency() {
      this.updateOperationStatus(this.operation.id, OPERATION_WORKFLOW.COM_PENDENCIAS);

      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_PENDENCIA,
        idOperation: this.operation.id,
        customerComplementaryInfo: this.pendencyComplentaryInfo,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {
          this.sendMessage("E-mail de pendência documental enviado com sucesso!", "success");
          this.pendencyComplentaryInfoModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    analysisOperationPendency(operation) {
      this.updateOperationStatus(operation.id, OPERATION_WORKFLOW.ANALISE_PENDENCIAS);
    },

    openDocumentControlModal(operation) {
      this.operation = operation;
      this.modalKey++;
      this.documentControlModal = true;
    },

    documentPostSaveCallback(response) {
      this.receivedDocumentTrigger(response.data);

      let operation = this.operations.find(o => o.id == response.data.operationId);

      if (OPERATION_DOCUMENT_TYPES.CC.id == response.data.operationDocumentType?.id && !operation.exchangeBuy) {
        this.updateOperationStatus(response.data.operationId, OPERATION_WORKFLOW.EM_LIQUIDACAO);
      } else if (OPERATION_DOCUMENT_TYPES.SWIFT.id == response.data.operationDocumentType?.id || OPERATION_DOCUMENT_TYPES.CC.id == response.data.operationDocumentType?.id) {
        this.updateOperationStatus(response.data.operationId, OPERATION_WORKFLOW.LIQUIDADA);
      }

      this.key++;
    },

    customSort(items, sortBy, sortDesc) {
      const customFields = [
        {
          field: 'cc',
          getDescription: (a, b) => {
            return {
              valueA: !!a.cc.path,
              valueB: !!b.cc.path
            };
          }
        },
        {
          field: 'swift',
          getDescription: (a, b) => {
            return {
              valueA: !!a.swift.path,
              valueB: !!b.swift.path
            };
          }
        },
        {
          field: 'didue',
          getDescription: (a, b) => {
            return {
              valueA: a.nature.useDiDue ? a.didue.path : '-',
              valueB: b.nature.useDiDue ? b.didue.path : '-'
            };
          }
        }
      ];

      return customSortDataTable(items, sortBy, sortDesc, customFields);
    }
  },
};
</script>

<style scoped></style>
