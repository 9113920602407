function getValue(obj, path) {
  return path?.split('.').reduce((acc, part) => acc && acc[part], obj);
}

function removeAccentsAndPunctuation(text) {
  const onlyNumbers = /^[0-9.]+$/;

  if (onlyNumbers.test(text))
    return Number(text);

  if (['boolean', 'object'].includes(typeof text))
    return text;

  return text?.toLowerCase().replace(/[\u0300-\u036f]|[^\w\s]/g, '');
} 

function returnItensByOrder(valueA, valueB, sortDesc) {
  valueA = removeAccentsAndPunctuation(valueA);
  valueB = removeAccentsAndPunctuation(valueB);

  if (valueA === undefined) return sortDesc ? 1 : -1;

  if (valueB === undefined) return sortDesc ? -1 : 1;

  return sortDesc
    ? (valueA < valueB ? 1 : (valueA > valueB ? -1 : 0))
    : (valueA > valueB ? 1 : (valueA < valueB ? -1 : 0));
}

function customSortDataTable(items, sortBy, sortDesc, customFields) {
  if (!sortBy?.length) return items;

  for (let i = 0; i < customFields.length; i++) {
    let item = customFields[i];

    if (sortBy.includes(item.field)) {
      return items.sort((a, b) => {
        let { valueA, valueB } = item.getDescription(a, b);

        return returnItensByOrder(valueA, valueB, sortDesc[0]);
      });
    }
  }

  return items.sort((a, b) => returnItensByOrder(getValue(a, sortBy[0]), getValue(b, sortBy[0]), sortDesc[0]));
}

export { customSortDataTable };